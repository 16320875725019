import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPause, faPlay, faTimes } from '@fortawesome/free-solid-svg-icons'
import { EqualHeight, EqualHeightElement } from 'react-equal-height';
import { Modal } from 'react-bootstrap';
import ReactPlayer from 'react-player'
import { CountUp } from 'countup.js/dist/countUp.min.js';
import Swal from 'sweetalert2';
import axios from "axios";
import { meditations } from "./config";
import { Link, useNavigate } from 'react-router-dom';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
// var MockAdapter = require("axios-mock-adapter");
// var mock = new MockAdapter(axios);
// mock.onPost("/subscribeEmail").reply(200, {
//   emailSubscribed: true
// });
let intervalForward = null;
let intervalReverse = null;
let counter = 1;

function SamplePrevArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div
			className={className}
			style={{ ...style, display: "block", }}
			onClick={onClick}
		>
			<img src="./images/left-chevron.png" className="t-i-slider-next-prev prev" />
		</div>
	);
}

function SampleNextArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div
			className={className}
			style={{ ...style, display: "block", }}
			onClick={onClick}
		>
			<img src="./images/right-chevron.png" className="t-i-slider-next-prev next" />
		</div>
	);
}

function App() {

	const initializeSliderAuthors = () => {
		// alert('here');
	};

	useEffect(() => {
		initializeSliderAuthors();
		window.addEventListener('scroll', handleScroll);
		startForwardInterval();
		return () => {
			window.removeEventListener('scroll', handleScroll);
		}
	}, []);

	const startForwardInterval = () => {
		intervalForward = setInterval(() => {
			// console.log('[COUNTER counter]', counter);
			const elementToCheck = document.getElementById(`headerAbsImgCircle${counter}`);
			if (elementToCheck) {
				// document.getElementById(`headerAbsImg`);
				elementToCheck.classList.add('show');
			}
			counter = counter + 1;
			if (counter > 5) {
				clearInterval(intervalForward);
				startReverseInterval();
			}
		}, 400);
	}

	const startReverseInterval = () => {
		intervalReverse = setInterval(() => {
			// console.log('[COUNTER counter]', counter);
			const elementToCheck = document.getElementById(`headerAbsImgCircle${counter}`);
			if (elementToCheck) {
				// document.getElementById(`headerAbsImg`);
				elementToCheck.classList.remove('show');
			}
			counter = counter - 1;
			if (counter <= 0) {
				clearInterval(intervalReverse);
				startForwardInterval();
			}
		}, 400);
	}

  useEffect(() => {
    return () => {
      clearInterval(intervalForward);
      clearInterval(intervalReverse);
    }
  }, [])

	const handleScroll = (event) => {
		let scrollTop = document.documentElement.scrollTop;
		if (scrollTop > 130 && !document.body.classList.contains('header-fixed')) {
			document.body.classList.add('header-fixed');
		}
		if (scrollTop <= 130) {
			document.body.classList.remove('header-fixed');
		}
	}

	const [currentSlideAuthor, setCurrentSlideAuthor] = useState(0);
	const [showPaymentDetailsPopup, setShowPaymentDetailsPopup] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const [paymentFirstName, setPaymentFirstName] = useState(null);
  const [paymentLastName, setPaymentLastName] = useState(null);
  const [paymentEmail, setPaymentEmail] = useState(null);
  const [requiredFieldsError, setRequiredFieldsError] = useState(false);
  let navigate = useNavigate();

	const authorsSliderSettings = {
		dots: true,
		centerMode: true,
		arrows: false,
		dots: false,
		touchThreshold: 100,
		infinite: true,
		slidesToShow: 2,
		slidesToScroll: 2,
		adaptiveHeight: true,
		initialSlide: 1,
		beforeChange: current => setCurrentSlideAuthor(current),
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					infinite: true
				}
			},
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					infinite: true
				}
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					infinite: true
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true
				}
			}
		],
	}

	const meditationSliderSettings = {
		dots: false,
		centerMode: true,
		arrows: false,
		dots: false,
		touchThreshold: 100,
		infinite: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
		responsive: [
			{
				breakpoint: 767,
				settings: {
					centerMode: true,
					centerPadding: "30px",
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					centerMode: true,
					centerPadding: "30px",
				}
			}
		],
	}

	const pricingSliderSettings = {
		dots: false,
		centerMode: true,
		arrows: false,
		dots: false,
		touchThreshold: 100,
		infinite: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
		initialSlide: 0,
		responsive: [
			{
				breakpoint: 767,
				settings: {
					centerMode: true,
					centerPadding: "30px",
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					centerMode: true,
					centerPadding: "30px",
				}
			}
		],
	}

	const topHighlightSliderSettings = {
		dots: false,
		centerMode: false,
		arrows: false,
		dots: false,
		autoplaySpeed: 2000,
		touchThreshold: 100,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: false,
		autoplay: true,
		autoplaySpeed: 3000,
		fade: true,
		pauseOnHover: false
	}

	const testimonialSliderSettings = {
		centerMode: false,
		arrows: false,
		dots: false,
		touchThreshold: 100,
		// infinite: true,
		centerPadding: "0px",
		slidesToShow: 3,
		// variableWidth: true,
		className: "center",
		slidesToScroll: 1,
		adaptiveHeight: true,
		centerMode: true,
		// initialSlide: 1,
		responsive: [
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
					centerMode: true,
					centerPadding: "50px",
				}
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					centerMode: true,
					centerPadding: "50px",
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					centerMode: true,
					centerPadding: "20px",
				}
			}
		],
	}
	const openVideoPlayer = () => {
		setShowVideoPlayer(true);
	}
	const mobileSliderSettings = {
		centerMode: false,
		arrows: true,
		dots: false,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
		touchThreshold: 100,
		slidesToShow: 1,
		className: "mobile-slide-item",
		slidesToScroll: 1,
		adaptiveHeight: true,
		responsive: [
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true
				}
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		],
	}

	const handleCloseVideoPlayer = () => {
		setShowVideoPlayer(false);
	}

	const [showVideoPlayer, setShowVideoPlayer] = useState(false);
	const [videoPlayerUrl, setVideoPlayerUrl] = useState('https://vimeo.com/637515444');
	const [ratingItems, setRatingItems] = useState([
		{
			title: "AVG. Rating",
			ratingNumber: 5.0,
			ratingText: '',
			decimalPlaces: 1,
			imageUrl: "./imagesV2/star.png"
		},
		{
			title: "Total Reviews",
			ratingNumber: 3.1,
			ratingText: 'K',
			decimalPlaces: 1,
			imageUrl: "./imagesV2/chat.png"
		},
		{
			title: "Downloads",
			ratingNumber: 112,
			ratingText: 'K',
			decimalPlaces: 0,
			imageUrl: "./imagesV2/download.png"
		}
	])
	const [topBannerText, setTopBannerText] = useState('Guided audio content for')
	const [topBannerHighlightItems, setTopBannerHighlightItems] = useState([
		'better sleep', 'abundance', 'prosperity', 'manifesting', 'happiness', 'confidence'
	])
	const [tapIntoDescriptionText] = useState('Retreat Sounds gives full access to an abundant collection of meditations, classes and conversations for lviing an abundant and prosperous life. Fall in love with ancient wisdom and modern spirituality!');
	const mindfulMeditationDescription = useState('Increase your presence and awareness through our vast library of expert crafted guided meditations. Listen to our previews below!');
	const [mindfulMeditationTitle] = useState('Guided meditations');
	const [isPlayingId, setIsPlayingId] = useState();
	const [meditationItems] = useState([
		{
			id: 1,
			imageUrl: './imagesV2/guided/rest.png',
			title: 'Rest & Relax',
			subtitle: 'Sleep',
			audioUrlToPlay: meditations.restAndRelax
		},
		{
			id: 2,
			imageUrl: './imagesV2/guided/ignite.png',
			title: 'Ignite',
			subtitle: 'Focus',
			audioUrlToPlay: meditations.ignite
		},
		{
			id: 3,
			imageUrl: './imagesV2/guided/open-heart.png',
			title: 'Open Heart',
			subtitle: 'Health',
			audioUrlToPlay: meditations.openHeart
		},
		{
			id: 4,
			imageUrl: './imagesV2/guided/forgiveness.png',
			title: 'Forgiveness',
			subtitle: 'Love',
			audioUrlToPlay: meditations.forgive
		},
		{
			id: 5,
			imageUrl: './imagesV2/guided/easy-thoughts.png',
			title: 'Easy Thoughts',
			subtitle: 'Balance',
			audioUrlToPlay: meditations.easyThoughts
		},
		{
			id: 6,
			imageUrl: './imagesV2/guided/freedom.png',
			title: 'Freedom',
			subtitle: 'Energy',
			audioUrlToPlay: meditations.freedom
		},
	])
	const [classesSectionTitle] = useState('Courses with today’s top wisdom leaders');
	const [classesSectionDescription] = useState('Learn the laws of attraction, mindfulness, science, spirituality, and healing techniques. Take a listen!');
	const [authorSliderItems] = useState([
		{
			id: 7,
			imageUrl: './images/carousel-1.png',
			title: 'David Wolfe',
			designation: 'Author & Speaker',
			audioUrlToPlay: meditations.davidWolfe
		},
		{
			id: 8,
			imageUrl: './images/carousel-2.png',
			title: 'Dr. Shefali Tsabary',
			designation: 'Author & Speaker',
			audioUrlToPlay: meditations.shefali
		},
		{
			id: 9,
			imageUrl: './images/carousel-1.png',
			title: 'David Wolfe',
			designation: 'Author & Speaker',
			audioUrlToPlay: meditations.davidWolfe
		},
		{
			id: 10,
			imageUrl: './images/carousel-2.png',
			title: 'Dr. Shefali Tsabary',
			designation: 'Author & Speaker',
			audioUrlToPlay: meditations.shefali
		},
	])
	const [testimonialSliderItems] = useState([
		{
			text: '“Phenomenal!! I leave it running in the background while I am working on my computer/business!! Uplifting and keeps me focused all day long!!”',
			name: 'Jayckins',
			imageUrl: './imagesV2/testimonials/image2.png',
			numberOfStars: 5,
			title: "My everyday app for meditatation"
		},
		{
			text: '“This app really helped me a lot to relax my mind and to think positive not minding stressful thoughts. 😥 One of the best apps. So love it!”',
			name: 'Antonette L.',
			imageUrl: './imagesV2/testimonials/image3.png',
			numberOfStars: 5,
			title: "Relaxes my mind"
		},
		{
			text: '“This app helps me a lot to relax my mind! lately, i had trouble sleeping but by listening tru this app, i can sleep well. highly recommended! thank you!”',
			name: 'Janine 0.',
			imageUrl: './imagesV2/testimonials/image1.png',
			numberOfStars: 5,
			title: "Sleeping like a baby"
		},
		{
			text: '“Phenomenal!! I leave it running in the background while I am working on my computer/business!! Uplifting and keeps me focused all day long!!”',
			name: 'Jayckins',
			imageUrl: './imagesV2/testimonials/image2.png',
			numberOfStars: 5,
			title: "My everyday app for meditatation"
		},
		{
			text: '“This app really helped me a lot to relax my mind and to think positive not minding stressful thoughts. 😥 One of the best apps. So love it!”',
			name: 'Antonette L.',
			imageUrl: './imagesV2/testimonials/image3.png',
			numberOfStars: 5,
			title: "Relaxes my mind"
		},
		{
			text: '“This app helps me a lot to relax my mind! lately, i had trouble sleeping but by listening tru this app, i can sleep well. highly recommended! thank you!”',
			name: 'Janine 0.',
			imageUrl: './imagesV2/testimonials/image1.png',
			numberOfStars: 5,
			title: "Sleeping like a baby"
		},
	])
	const [pricingSectionTitle] = useState('Start your healing journey. Try it free for 30 days.')
	console.log('[isPlayingId]', isPlayingId);
	const onStop = () => {
		console.log('[AUDIO onStop]');
	}
	const [audioUrlToPlay, setAudioUrlToPlay] = useState(null);
	const [playingStatus, setPlayingStatus] = useState('PAUSED');
	const toggleIsPlayingId = (id) => {
		if (id == isPlayingId) {
			setIsPlayingId(null);
			setPlayingStatus('PAUSED');
		} else {
			setIsPlayingId(id);
			let audioUrlToPlay = '';
			if (meditationItems.find(item => item.id == id)) {
				audioUrlToPlay = meditationItems.find(item => item.id == id).audioUrlToPlay;
			} else if (authorSliderItems.find(item => item.id == id)) {
				audioUrlToPlay = authorSliderItems.find(item => item.id == id).audioUrlToPlay;
			}
			console.log('[audioUrlToPlay]', audioUrlToPlay);
			setAudioUrlToPlay(audioUrlToPlay);
			setPlayingStatus('PLAYING');
		}
	};
	const [startCount, setStartCount] = useState(false);
	let lastKnownScrollPosition = 0;
	let ticking = false;
	document.addEventListener('scroll', function (e) {
		lastKnownScrollPosition = window.scrollY;
		ticking = false;

		if (!ticking) {
			window.requestAnimationFrame(function () {
				if (!startCount && lastKnownScrollPosition > 200) {
					setStartCount(true);
				}
				ticking = false;
			});
			ticking = true;
		}
	});
	const [showEmailPopup, setShowEmailPopup] = useState(false);
	const [email, setEmail] = useState('');
	const swalWithBootstrapButtons = Swal.mixin({
		customClass: {
			confirmButton: 'app-button',
		},
		buttonsStyling: false
	})
	const addEmailToSubscribe = async () => {
		if (email && email !== '') {
			let data = { email };
			try {
				let response = axios.post('/subscribeEmail', data);
				if (response) {
					setShowEmailPopup(false);
					swalWithBootstrapButtons.fire({
						title: 'Success!',
						text: 'Successfully added email to our list',
						icon: 'success',
						confirmButtonText: 'Okay'
					})
				} else {
					setShowEmailPopup(false);
					swalWithBootstrapButtons.fire({
						title: 'Success!',
						text: 'Successfully added email to our list',
						icon: 'success',
						confirmButtonText: 'Okay'
					})
				}
			} catch (error) {
				setShowEmailPopup(false);
				swalWithBootstrapButtons.fire({
					title: 'Success!',
					text: 'Successfully added email to our list',
					icon: 'success',
					confirmButtonText: 'Okay'
				})
			}
		} else {
			swalWithBootstrapButtons.fire({
				title: 'Error!',
				text: 'Please add your email to proceed',
				icon: 'error',
				confirmButtonText: 'Okay'
			})
		}
	};
  const selectPlan = (category) => {
    console.log('[PAYMENT_DATA category]', category);
    setSelectedPlan(category);
    setShowPaymentDetailsPopup(true);
  }

  const subscribeToPlan = async () => {
    if (paymentFirstName && paymentFirstName !== '' && paymentLastName && paymentLastName !== '' && paymentEmail && paymentEmail !== '') {
      setRequiredFieldsError(false);
      try {
        let data = {
          plan: selectedPlan,
          firstName: paymentFirstName,
          lastName: paymentLastName,
          email: paymentEmail
        }
        console.log('[PAYMENT_DATA data]', data);
        const response = await axios.post('https://user-api-stage.retreatsounds.com/stripe/charge', data);
        if (response && response.data && response.data.data) {
          // url received
          window.location.href = response.data.data;
        } else {
          // failed
          throw 'ERROR';
        }
      } catch (error) {
        console.log('[PAYMENT_DATA error]', error);
        navigate("/paymentFailure")
      }
    } else {
      setRequiredFieldsError(true);
    }
  }

  const closePaymentDetailsPopup = () => {
    setShowPaymentDetailsPopup(false)
    setSelectedPlan(null);
    setPaymentFirstName(null);
    setPaymentLastName(null);
    setPaymentEmail(null);
  }
	return (
		<div className="site-container">
			<div className="h-a-player">
				<ReactPlayer
					url={audioUrlToPlay}
					playing={playingStatus === 'PLAYING' ? true : playingStatus === 'PAUSED' ? false : false}
					onEnded={() => onStop()}
					controls={true}
				/>
			</div>
			<Modal
				show={showVideoPlayer}
				onHide={handleCloseVideoPlayer}
				dialogClassName="modal-90w"
				centered
				size="lg"
				contentClassName="video-modal-cont"
			>
				<div className='player-wrapper'>
					<ReactPlayer
						className='react-player'
						url={videoPlayerUrl}
						width='100%'
						height='100%'
						controls={true}
					/>
				</div>
			</Modal>
			<Modal
				show={showEmailPopup}
				onHide={() => setShowEmailPopup(false)}
				centered
				size="md"
				contentClassName="email-cont-modal"
			>
				<div className="email-cont-modal-wrapper">
					<div className="email-inner-cont-modal-wrapper">
						<button onClick={() => setShowEmailPopup(false)} className="email-close-button-cont-modal-wrapper d-flex justify-content-center align-items-center"><FontAwesomeIcon className="email-close-button-cont-modal-icon" icon={faTimes} /></button>
						<div className="email-inner-cont d-flex justify-content-center flex-column">
							<div className="e-p-title">Unlock your<br /> <div class="h-d-banner-left-title-highlight">infinite</div> potential</div>
							<div className="e-p-tf-cont">
								<input onChange={(e) => setEmail(e.target.value)} type="email" class="e-p-tf-cont-text" placeholder="Your email" />
							</div>
							<div className="e-p-tf-button-cont">
								<button onClick={addEmailToSubscribe} className="app-button d-flex justify-content-center align-items-center">
									<div className="app-button-text">Get 1 month free access</div>
								</button>
							</div>
						</div>
					</div>
				</div>
			</Modal>
      <Modal
				show={showPaymentDetailsPopup}
				onHide={() => closePaymentDetailsPopup()}
				centered
				size="md"
				contentClassName="email-cont-modal"
			>
				<div className="email-cont-modal-wrapper">
					<div className="email-inner-cont-modal-wrapper">
						<button onClick={() => closePaymentDetailsPopup()} className="email-close-button-cont-modal-wrapper d-flex justify-content-center align-items-center"><FontAwesomeIcon className="email-close-button-cont-modal-icon" icon={faTimes} /></button>
						<div className="email-inner-cont d-flex justify-content-center flex-column">
							<div className="e-p-title">Enter your<br /> <div class="h-d-banner-left-title-highlight">details</div> before proceeding</div>
              <div className="e-p-tf-cont mb-10">
								<input onChange={(e) => setPaymentFirstName(e.target.value)} type="text" class="e-p-tf-cont-text" placeholder="Firstname" />
							</div>
              <div className="e-p-tf-cont mb-10">
              <input onChange={(e) => setPaymentLastName(e.target.value)} type="text" class="e-p-tf-cont-text" placeholder="Lastname" />
							</div>
							<div className="e-p-tf-cont mb-10">
								<input onChange={(e) => setPaymentEmail(e.target.value)} type="email" class="e-p-tf-cont-text" placeholder="Email" />
							</div>
              {requiredFieldsError ? (
                <div className="e-p-tf-cont mb-10">
                  <div className='e-p-tf-required-text'>All fields are required, please add details before proceeding</div>
                </div>
              ) : null}
							<div className="e-p-tf-button-cont">
								<button onClick={subscribeToPlan} className="app-button d-flex justify-content-center align-items-center">
									<div className="app-button-text">Subscribe to <span className='app-button-capitalize'>{selectedPlan}</span> plan</div>
								</button>
							</div>
						</div>
					</div>
				</div>
			</Modal>
			<div className="h-d-outer-cont">

				<div className="h-d-inner-cont">
					{/* <div id="headerAbsImg" className="h-d-abs-img"></div> */}
					<div id="headerAbsImgCircle1" className="header-image image1"></div>
					<div id="headerAbsImgCircle2" className="header-image image2"></div>
					<div id="headerAbsImgCircle3" className="header-image image3"></div>
					<div id="headerAbsImgCircle4" className="header-image image4"></div>
					<div id="headerAbsImgCircle5" className="header-image image5"></div>
					<Header />
					<div className="h-d-banner-cont">
						<div class="h-d-banner-mobile-cont-abs-image-bottom"></div>
						<div className="main-site-wrapper">
							<div className="h-d-banner-inner-cont">
								<div className="h-d-banner-left">
									<div className="h-d-banner-left-title-cont">
										<div className="h-d-banner-left-title">{topBannerText}</div>
										<Slider {...topHighlightSliderSettings}>
											{
												topBannerHighlightItems.map(item => {
													return <div className="h-d-banner-left-title-highlight">{item}</div>
												})
											}
										</Slider>
									</div>
									<div className="h-d-banner-left-buttons-cont d-flex flex-column align-items-start">
										<button onClick={() => window.open('https://offers.retreatsounds.com/order-page-3-months', '_blank').focus()} className="app-button big h-d-banner-left-button-left d-flex justify-content-center
                      align-items-center">
											<div className="app-button-text">Try It Free</div>
										</button>
										<button onClick={openVideoPlayer} className="app-button-outline big d-flex justify-content-center align-items-center flex-row">
											<FontAwesomeIcon className="app-button-outline-icon" icon={faPlay} />
											<div className="app-button-outline-text">Watch the video</div>
										</button>
									</div>
									{/* <div className="h-d-banner-app-image-cont">
                      <img className="h-d-banner-app-image" alt="" src="./images/app.png" />
                    </div> */}
								</div>

							</div>
						</div>
						{/* <div class="h-d-banner-cont-abs-image-bottom"></div> */}
					</div>
					<div className="h-d-banner-app-image-cont">
						<img className="h-d-banner-app-image" alt="" src="./imagesV2/hero-img-iphone.png" />
					</div>
					<div className="h-d-stats-cont">
						<div className="h-d-stats-inner-cont d-flex flex-row aling-items-center justify-content-center">
							{
								ratingItems.map((item, index) => {
									return <RatingItem startCount={startCount} decimalPlaces={item.decimalPlaces} index={index} title={item.title} ratingNumber={item.ratingNumber} ratingText={item.ratingText} imageUrl={item.imageUrl} />
								})
							}
						</div>
					</div>
					<div class="t-i-abs-image-top"></div>
				</div>

			</div>
			<div className="t-i-outer-cont">

				<div className="t-i-inner-cont">
					{/* <div class="t-i-abs-image-top"></div> */}
					{/* <div className="t-i-inner-abs-image-bottom" /> */}
					<div className="main-site-wrapper">
						<div className="t-i-right-cont">
							<div className="h-d-banner-left-title-cont">
								<div className="h-d-banner-left-title">Tap into your<br />zone of genius</div>
								<div className="h-d-banner-left-subtitle">{tapIntoDescriptionText}</div>

								<div className="h-d-banner-left-buttons-cont t-i-right-button-cont d-flex flex-row justify-content-center align-items-center">
									<button className="app-button big purple h-d-banner-left-button-left d-flex justify-content-center 
                  align-items-center">
										<div className="app-button-text more-l-s" onClick={() => window.open('https://offers.retreatsounds.com/order-page-3-months', '_blank').focus()}>raise your vibration</div>
									</button>
								</div>
							</div>

							<div className="t-i-right-image-cont">
								<div className="t-i-right-image-rel d-flex">
									<div className="t-i-right-image-abs" style={{ backgroundImage: `url('./imagesV2/meditation.png')` }}></div>
								</div>
							</div>
						</div>
						<div className="t-i-bottom-image-mobile-cont">
							<img src="./imagesV2/redefine.png" className="t-i-bottom-image-mobile" />
						</div>
					</div>
				</div>

			</div>
			<div className="m-m-outer-cont">
				<div className="m-m-cont-abs"></div>
				<div className="m-m-cont">
					<div className="m-m-inner-cont">
						<div className="main-site-wrapper">
							<div className="m-m-top-cont">
								<div className="m-m-top-title">{mindfulMeditationTitle}</div>
								<div className="m-m-top-subtitle">{mindfulMeditationDescription}</div>
							</div>
							<div className="m-m-middle-cont">
								<div className="m-m-middle-inner-cont d-none d-md-block">
									<div className="container-fluid">
										<div className="row-eq-height row">
											{
												meditationItems.map((item, index) => {
													return <MeditationItem imageUrl={item.imageUrl} title={item.title} subtitle={item.subtitle} isPlaying={isPlayingId == item.id} setIsPlaying={() => toggleIsPlayingId(item.id)} />
												})
											}
										</div>
									</div>
								</div>
								<div className="m-m-middle-inner-cont d-block d-md-none">
									<div className="m-m-middle-inner-cont-slider">
										<Slider {...meditationSliderSettings}>
											{
												meditationItems.map((item, index) => {
													return <MeditationItem isMobile={true} imageUrl={item.imageUrl} title={item.title} subtitle={item.subtitle} isPlaying={isPlayingId == item.id} setIsPlaying={() => toggleIsPlayingId(item.id)} />
												})
											}
										</Slider>
									</div>
								</div>
							</div>
							<div className="m-m-bottom-cont">
								<div className="m-m-bottom-inner-cont d-flex flex-column justify-content-center align-items-center">
									<button className="app-button purple big d-flex justify-content-center align-items-center">
										<div className="app-button-text more-l-s" onClick={() => window.open('https://offers.retreatsounds.com/order-page-3-months', '_blank').focus()}>Find your inner peace</div>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="c-t-cont">
					{/* <div className="c-t-inner-abs-image-bottom" /> */}

					<div className="main-site-wrapper">
						<div className="c-t-cont-rel">
							<div className="c-t-inner-cont">
								<div className="c-t-inner-abs-image" />
								<div className="c-t-title-cont">
									<div className="c-t-title">{classesSectionTitle}</div>
									<div className="c-t-subtitle">{classesSectionDescription}</div>
									<div className="c-t-bottom-cont">
										<div className="m-m-bottom-inner-cont d-flex flex-column justify-content-center align-items-lg-start align-items-center">
											<button className="app-button purple big d-flex justify-content-center align-items-center">
												<div className="app-button-text more-l-s" onClick={() => window.open('https://offers.retreatsounds.com/order-page-3-months', '_blank').focus()}>Learn ancient wisdom</div>
											</button>
										</div>
									</div>
								</div>
								<div className="c-t-carousel-cont-abs">
									<div className={`c-t-carousel-cont ${currentSlideAuthor === 0 ? 'first-slide' : null}`}>
										<div className="c-t-carousel-inner-cont">
											<div className="owl-carousel clearfix">
												<Slider {...authorsSliderSettings}>
													{
														authorSliderItems.map(item => {
															return <AuthorCarouselItem isPlaying={isPlayingId == item.id} setIsPlaying={() => toggleIsPlayingId(item.id)} imageUrl={item.imageUrl} title={item.title} designation={item.designation} />
														})
													}
												</Slider>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>
				<div className="t-m-cont">
					{/* <div className="t-m-inner-abs-image" /> */}
					<div className="t-m-inner-cont">
						{/* <div class="t-m-inner-abs-image-bottom"></div> */}
						<div className="main-site-wrapper">
							<div className="t-m-title-cont d-flex justify-content-center align-items-center flex-column">
								<div className="t-m-title-text">What people are saying</div>
								<div className="t-m-slider-cont d-flex flex-fill">
									{/* <div className="container-fluid">
                      <div className="row">
                        <div className="col-sm-12"> */}
									<div className="t-m-slider-inner-cont">
										<div id="customers-testimonials" className="owl-carousel clearfix">
											<EqualHeight timeout={1000}>
												<Slider {...testimonialSliderSettings}>
													{
														testimonialSliderItems.map(item => {
															return (
																<TestimonialCarouselItem title={item.title} numberOfStars={item.numberOfStars} text={item.text} name={item.name} imageUrl={item.imageUrl} />
															)
														})
													}
												</Slider>
											</EqualHeight>
										</div>
									</div>
									{/* </div>
                      </div>
                    </div> */}
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
			<div className="h-j-cont">
				<div className="main-site-wrapper">
					<div className="h-j-outer-cont">
						{/* <div className="h-j-abs-image-cont"></div> */}
						<div className="h-j-inner-cont d-flex flex-column">
							<div className="h-j-title">{pricingSectionTitle}</div>

							<div className="h-j-boxes-cont">
								<div className="m-m-middle-inner-cont h-j-middle-inner-cont d-none d-lg-block">
									<div className="container-fluid">
										<div className="row row-eq-height justify-content-center">
											<EqualHeight>
												{/* <PricingItem title={'Free Trial'} arrayOfItems={['30 days free', 'Unlimited access to the complete Retreat Sounds library', '$19.95/mo after 30 days', 'Cancel anytime']} /> */}
												<PricingItem title={'Monthly'} selectPlan={() => selectPlan('MONTHLY')} arrayOfItems={['Unlimited access to the complete Retreat Sounds library', 'Access to the latest tips, content, and events', '$12.99/mo', 'Cancel anytime']} linkToOpen='https://offers.retreatsounds.com/order-page-3-months' />
                        <PricingItem title={'Annual'} selectPlan={() => selectPlan('ANNUAL')} arrayOfItems={['Unlimited access to the complete Retreat Sounds library', 'Access to the latest tips, content, and events', '$59.99/year', 'Cancel anytime']} linkToOpen='https://offers.retreatsounds.com/order-page-3-months' />
                        <PricingItem selectPlan={() => selectPlan('LIFETIME')} isViolet={true} title={'Lifetime'} arrayOfItems={['Unlimited access to the complete Retreat Sounds library', 'Save more than 17% vs. monthly', 'Access to the latest tips, content, and events', '$399.99', 'Cancel anytime']} linkToOpen='https://offers.retreatsounds.com/order-page-annual' />
											</EqualHeight>
										</div>
									</div>
								</div>
								<div className="m-m-middle-inner-cont h-j-middle-inner-cont d-block d-lg-none  justify-content-center">
									<EqualHeight>
										<Slider {...pricingSliderSettings}>
											<PricingItem title={'Monthly'} selectPlan={() => selectPlan('MONTHLY')} arrayOfItems={['Unlimited access to the complete Retreat Sounds library', 'Access to the latest tips, content, and events', '$12.99/mo', 'Cancel anytime']} linkToOpen='https://offers.retreatsounds.com/order-page-3-months' />
                      <PricingItem title={'Annual'} selectPlan={() => selectPlan('ANNUAL')} arrayOfItems={['Unlimited access to the complete Retreat Sounds library', 'Access to the latest tips, content, and events', '$59.99/year', 'Cancel anytime']} linkToOpen='https://offers.retreatsounds.com/order-page-3-months' />
                      <PricingItem selectPlan={() => selectPlan('LIFETIME')} isViolet={true} title={'Lifetime'} arrayOfItems={['Unlimited access to the complete Retreat Sounds library', 'Save more than 17% vs. monthly', 'Access to the latest tips, content, and events', '$399.99', 'Cancel anytime']} linkToOpen='https://offers.retreatsounds.com/order-page-annual' />
										</Slider>
									</EqualHeight>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</div>
	);
}

function AuthorCarouselItem(props) {
	const { imageUrl, title, designation, isPlaying, setIsPlaying } = props;
	return (
		<div className={`c-t-carousel-item ${isPlaying ? 'pause' : ''}`}>
			{/* <div className="c-t-carousel-item-inner d-flex flex-column justify-content-end" style={{backgroundImage: `linear-gradient(180deg,rgba(0, 0, 0, 0) 67.35%, #000000 100%), url('${imageUrl}')`}}> */}
			<div className="c-t-carousel-item-inner d-flex flex-column justify-content-end" style={{ backgroundImage: `linear-gradient(180deg,rgba(0, 0, 0, 0) 67.35%, #000000 100%)` }}>
				<img className="c-t-carousel-item-inner-image" src={imageUrl} />
				<div className="c-t-carousel-item-bottom-cont d-flex flex-row justify-content-between">
					<div className="c-t-carousel-item-bottom-left d-flex flex-fill flex-column justify-content-center">
						<div className="c-t-carousel-item-bottom-left-title">{title}</div>
						<div className="c-t-carousel-item-bottom-left-subtitle">{designation}</div>
					</div>
					<div className="c-t-carousel-item-bottom-right">
						<button onClick={() => setIsPlaying()} className="m-m-middle-item-play-button m-m-bottom-item-play-button d-flex justify-content-center align-items-center">
							{
								isPlaying ?
									<FontAwesomeIcon className="m-m-middle-item-play-button-icon white" icon={faPause} />
									:
									<FontAwesomeIcon className="m-m-middle-item-play-button-icon white" icon={faPlay} />
							}
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}

function TestimonialCarouselItem(props) {
	const { imageUrl, text, name, title, numberOfStars } = props;
	let items = [];
	for (let i = 0; i < numberOfStars; i++) {
		items.push(<div className="testimonial-star-item"><img src="./images/star-t.png" className="testimonial-star-item-image" /></div>)
	}
	return (
		<div className="item">
			<div className="shadow-effect">
				<EqualHeightElement name="TestimonialImage">
					<img className="img-circle" src={`${imageUrl}`} alt="" />
				</EqualHeightElement>
				<div className="testimonial-text-cont">
					<EqualHeightElement name="TestimonialText">
						<div className="testimonial-star-cont d-flex flex-row justify-content-center align-items-center">{items}</div>
						<div className="testimonial-header-text">{title}</div>
						<p>{text}</p>
						<div className="testimonialName">{name}</div>
					</EqualHeightElement>
				</div>
			</div>
		</div>
	)
}

function MeditationItem(props) {
	const { imageUrl, title, isPlaying, subtitle, setIsPlaying } = props;
	return (
		<div className={`col-12 col-sm-12 col-md-4 col-lg-4 m-m-middle-item-cont ${isPlaying ? 'pause' : ''}`}>
			<div className="m-m-middle-item-inner flex-fill selected d-flex flex-column justify-content-between align-items-center" style={{ backgroundImage: `url(${imageUrl})` }}>
				<div className="m-m-middle-item-title-cont">
					<div className="m-m-middle-item-subtitle" dangerouslySetInnerHTML={{ __html: subtitle }} />
					<div className="m-m-middle-item-title" dangerouslySetInnerHTML={{ __html: title }} />
				</div>
				<div className="m-m-middle-item-play-cont">
					<button onClick={() => setIsPlaying()} className="m-m-middle-item-play-button d-flex justify-content-center align-items-center">
						{
							isPlaying ?
								<FontAwesomeIcon className="m-m-middle-item-play-button-icon" icon={faPause} />
								:
								<FontAwesomeIcon className="m-m-middle-item-play-button-icon" icon={faPlay} />
						}
					</button>
				</div>
			</div>
		</div>
	)
}

function PricingItem(props) {
	const { title, arrayOfItems, isViolet, selectPlan } = props;
	return (
		<div className="col-12 col-sm-12 col-md-12 col-lg-4 h-j-box-item-cont-outer">
			<EqualHeightElement name="Name">
				<div className={`h-j-box-item-cont ${isViolet ? 'violet' : ''}`}>
					<div className="h-j-box-item-inner-cont">
						{
							isViolet ?
								<div className="h-j-b-v-box d-flex flex-column justify-content-center align-items-center">
									<div className="h-j-b-v-box-inner">Best Value</div>
								</div>
								:
								null
						}
						<div className="h-j-box-item-title-cont">{title}</div>
						<div className="h-j-box-item-content-cont">
							{
								arrayOfItems.map(item => {
									return (
										<div className="h-j-box-item-content-item">{item}</div>
									)
								})
							}
						</div>
					</div>
				</div>
			</EqualHeightElement>
			<div className="h-j-bottom-button-cont d-flex justify-content-center align-items-center">
				<button onClick={selectPlan} className="app-button purple big d-flex justify-content-center align-items-center">
					<div className="app-button-text more-l-s">Subscribe</div>
				</button>
			</div>
		</div>

	)
}
function RatingItem(props) {
	const { title, imageUrl, ratingNumber, ratingText, index, decimalPlaces, startCount } = props;

	// useEffect(() => {
	//   setTimeout(() => {
	//     const countUp = new CountUp(`rating-item-${index}`, ratingNumber, { decimalPlaces: decimalPlaces });
	//     console.log('[countUp]', countUp)
	//     if (!countUp.error) {
	//       // setTimeout(() => {
	//         if (startCount) {
	//           countUp.start();
	//         }
	//       // }, 3000)
	//     } else {
	//       console.error(countUp.error);
	//     }
	//   })
	// }, [])
	useEffect(() => {
		setTimeout(() => {
			const countUp = new CountUp(`rating-item-${index}`, ratingNumber, { decimalPlaces: decimalPlaces });
			console.log('[countUp]', countUp)
			if (!countUp.error) {
				// setTimeout(() => {
				if (startCount) {
					countUp.start();
				}
				// }, 3000)
			} else {
				console.error(countUp.error);
			}
		})
	}, [startCount])

	return (

		<div className="h-d-stats-item d-flex flex-column justify-content-between align-items-center">
			<img className="h-d-stats-item-image" alt="" src={imageUrl} />
			<div className="h-d-stats-item-title"><span id={`rating-item-${index}`}></span>{ratingText}</div>
			<div className="h-d-stats-item-subtitle">{title}</div>
		</div>

	)
}

export default App;
